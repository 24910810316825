export default [
  {
    path: '/apps/usecases',
    name: 'apps-usecases',
    component: () => import('@/views/dashboard/customer/Dashboard.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Usecases',
      breadcrumb: [
        {
          text: 'Usecases',
        }
      ],
    },
  },
  {
    path: '/apps/faq',
    name: 'apps-faq',
    component: () => import('@/views/apps/faq/Faq.vue'),
    meta: {
      resource: 'Shared',
      action: 'read',
      pageTitle: 'FAQ',
      breadcrumb: [
        {
          text: 'FAQ'
        },
      ],
    },
  },
  {
    path: '/apps/book-a-time',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Book a Time',
      breadcrumb: [
        {
          text: 'Book Time'
        },
      ],
    },
  },
  {
    path: '/apps/customers',
    name: 'app-customers',
    component: () => import('@/views/apps/customers/Customers.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
      pageTitle: 'Customers',
      breadcrumb: [
        {
          text: 'Customers'
        },
      ],
    },
  },
  {
    path: '/apps/products',
    name: 'apps-products',
    component: () => import('@/views/apps/product/product-list/ProductList.vue'),
    meta: {
      resource: 'Shared',
      action: 'read',
      pageTitle: 'My Products',
      breadcrumb: [
        {
          text: 'My Products'
        }
      ],
    },
  },
  {
    path: '/apps/market-place',
    name: 'apps-market-place',
    component: () => import('@/views/apps/market-intergration/MarketPlace.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Market Place',
      breadcrumb: [
        {
          text: 'AWS Marketplace'
        },
      ],
    },
  },
  {
    path: '/apps/market-place/intergration',
    name: 'apps-market-place-intergration',
    component: () => import('@/views/apps/market-intergration/market-wizard/MarketWizard.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Market Place',
      breadcrumb: [
        {
          text: 'Market Place Intergration'
        },
      ],
    },
  },
  {
    path: '/apps/products/:slug',
    name: 'apps-products-details',
    component: () => import('@/views/apps/product/product-details/ProductDetails.vue'),
    meta: {
      resource: 'Shared',
      action: 'read',
      pageTitle: 'Products',
      breadcrumb: [
        {
          text: 'Products Details'
        }
      ],
    },
  },
  {
    path: '/apps/add-product',
    name: 'apps-add-product',
    component: () => import('@/views/apps/product/product-add/ProductAdd.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
      pageTitle: 'Product',
      breadcrumb: [
        {
          text: 'Add Product'
        },
      ],
    },
  },
  {
    path: '/apps/edit-product/:slug',
    name: 'apps-edit-product',
    component: () => import('@/views/apps/product/product-edit/ProductEdit.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
      pageTitle: 'Product',
      breadcrumb: [
        {
          text: 'Edit Product'
        },
      ],
    },
  },
  // {
  //   path: '/apps/license',
  //   name: 'apps-license',
  //   component: () => import('@/views/apps/license/License.vue'),
  //   meta: {
  //     resource: 'Shared',
  //     action: 'read',
  //     pageTitle: 'License',
  //     breadcrumb: [
  //       {
  //         text: 'License'
  //       },
  //     ],
  //   },
  // },
  {
    path: '/apps/notifications',
    name: 'apps-notifications',
    component: () => import('@/views/apps/notifications/Notifications.vue'),
    meta: {
      resource: 'Shared',
      action: 'read',
      pageTitle: 'Notifications',
      breadcrumb: [
        {
          text: 'Notifications'
        },
      ],
    },
  },
  {
    path: '/apps/account-settings',
    name: 'apps-view-profile',
    component: () => import('@/views/apps/user/User.vue'),
    meta: {
      resource: 'Shared',
      action: 'read',
      pageTitle: 'Account Settings',
      breadcrumb: [
        {
          text: 'Account Settings'
        },
      ],
    },
  },
  {
    path: '/apps/edit-profile',
    name: 'apps-edit-profile',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
    meta: {
      resource: 'Shared',
      action: 'read',
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Edit Profile'
        },
      ],
    },
  },
  {
    path: '/apps/contact',
    name: 'apps-contact',
    component: () => import('@/views/apps/contact/Contact.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Contact',
      breadcrumb: [
        {
          text: 'Contact',
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/solutions',
    name: 'apps-solutions',
    component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Solutions',
      breadcrumb: [
        {
          text: 'Solutions',
        },
      ],
    },
  },
  {
    path: '/apps/service-catalogue/:slug',
    name: 'apps-e-commerce-product-details',
    component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Service Catalogue',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Product Details',
        },
      ],
    },
  },
  {
    path: '/apps/service-catalogue/checkout',
    name: 'apps-e-commerce-checkout',
    component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
    meta: {
      resource: 'ACL',
      pageTitle: 'Service Catalogue',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Checkout',
          active: true,
        },
      ],
    },
  },
]
