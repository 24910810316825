
export default {
  namespaced: true,
  state: {
    refs: null,
    userInfo: null
  },
  getters: {},
  mutations: {
    RESET_HOME_MODULE(state) {
      state.refs = null
    },
    UPDATE_REFS(state, value) {
      state.refs = value
    },
    UPDATE_USER_INFO(state, value) {
      state.userInfo = value
    }
  },
}
