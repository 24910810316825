import { Auth } from 'aws-amplify';

/**
 * Check user Authentication status. 
*/
export const checkUserStatus = async () => {
  try {
    const status = await Auth.currentAuthenticatedUser()
    return status
  } catch (error) {
    throw new Error(error)
  }
}

/**
 * Sign in user to Automatum application
 * @param username // required this should be user email address
 * @param password // required user password
*/
export const signIn = async (username, password) => {
  try {
    const user = await Auth.signIn(username, password);
    return user
  } catch (error) {
    throw new Error(error)
  }
}
/**
 * Signup user to Automatum application
 * @param username // required this should be user email address
 * @param password // required user password
*/
export const signUp = async (username, password, email) => {
  try {
    const { user } = await Auth.signUp({
      username,
      password,
      attributes: {
        email
      }
    });
    return user
  } catch (error) {
    throw new Error(error)
  }
}
/**
  * Automatum to send emails with code to verify user account
  * Once a code has been received then they can verify there account
  * @param username // required username
  * @param code // required code
 */
export const confirmSignUp = async (username, code) => {
  try {
    await Auth.confirmSignUp(username, code);
  } catch (error) {
    throw new Error(error)
  }
}
/**
  * When a user attempt to confirm there account and they loose
  * there code then they can request for another code.
  * @param username // required username
 */
export const resendConfirmationCode = async (username) => {
  try {
    await Auth.resendSignUp(username);
  } catch (err) {
    throw new Error(error)
  }
}
/**
 * Sigout user from Automatum Application
*/
export const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    throw new Error(error)
  }
}
/**
 * Incase Automatum will be using several application a user can signout
 * On all devices using the following function.
*/
export const globalSignOut = async () => {
  try {
    await Auth.signOut({ global: true });
  } catch (error) {
    throw new Error(error)
  }
}

/**
 * Send code to Automatum users so they are able to reset there accounts
 * A code will be sent back to the user.
*/
export const sendCodeResetPassword = async (username) => {
  try {
    // Send confirmation code to user's email
    const code = await Auth.forgotPassword(username)
    return code
  } catch (error) {
    throw new Error(error)
  }
}

/**
 * This will allow Automatum users to reset there passwords
*/
export const resetPassword = async (username, code, new_password) => {
  try {
    await Auth.forgotPasswordSubmit(username, code, new_password)
  } catch (error) {
    console.log(error);
  }
}

/**
 * Recover user account
*/
export const recoverAccount = async () => {
  try {
    const statusCode = await Auth.verifyCurrentUserAttribute(attr)
    return statusCode
  } catch (error) {
    console.log(error);
  }
}

/**
* Account recovery verification
*/
export const accountRecoveryVerification = async (attr) => {
  try {
    await Auth.verifyCurrentUserAttributeSubmit(attr, 'the_verification_code')
  } catch (error) {
    console.log(error)
  }
}

/**
* Get access tokens
*/
export const getAccessToken = async () => {
  try {
    const response = await Auth.currentSession()
    return response.idToken
  } catch (error) {
    console.log(error)
  }
}