/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:e9b43b09-c2be-4762-be88-9ce6de5f773e",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_4fw2G9xRb",
    "aws_user_pools_web_client_id": "7r5vj2b33sc06od5gn44m8qg0s",
    "oauth": {}
};


export default awsmobile;
