import { checkUserStatus } from "./amplify/amplifyService"

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = async () => {
  try {
    const isAuthenticated = await checkUserStatus()
    if (isAuthenticated) return localStorage.getItem('userData')
  } catch (error) {
    console.log(error)
  }

}

export const getUserData = () => {
  const savedData = localStorage.getItem('userData')
  if (savedData !== 'undefined' || savedData !== undefined) {
    return JSON.parse(savedData)
  } else {
    if (savedData == 'undefined') {
      localStorage.removeItem("userData")
    }
    return null
  }
}

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userData
 */

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = user => {
  if (user.user__role == 'admin') return "/dashboard/ecommerce"
  else if (user.user__role == 'customer') return "/apps/solutions"
  return { name: 'auth-login' }
}
