export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Analytics',
        }
      ],
    },
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'ECommerce',
        }
      ],
    },
  },
  {
    path: '/dashboard/customer',
    name: 'dashboard-customer',
    component: () => import('@/views/dashboard/customer/Dashboard.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Customer',
        }
      ],
    },
  }
]
