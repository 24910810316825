import { uniqBy } from "lodash"

export default {
  namespaced: true,
  state: {
    products: [],
    selectedProduct: null
  },
  getters: {
    //
  },
  mutations: {
    RESET_PRODUCTS_MODULE(state){
      state.products = []
      state.selectedProduct = null
    },
    UPDATE_PRODUCTS(state, value) {
      state.products = value
    },
    UPDATE_SELECTED_PRODUCT(state, value) {
      state.selectedProduct = value
    }
  },
  actions: {
    SAVE_PRODUCTS({ commit }, value) {
      commit("UPDATE_PRODUCTS", uniqBy(value, 'product_id'))
    },
    SAVE_SELECTED_PRODUCT({ commit }, value){
      commit("UPDATE_SELECTED_PRODUCT", value)
    }
  },
}
