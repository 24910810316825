import axios from "axios"
import { getAccessToken } from "../auth/amplify/amplifyService"

const _automatumURL = process.env.VUE_APP_APIGATEWAY
const _automatumBusinessId = process.env.VUE_APP_BUSINESSID

/**
 * Get all users
 * @param request {object}
*/
export const getAllUsers = async () => {
  const accessToken = await getAccessToken()
  const config = {
    method: 'get',
    url: `${_automatumURL}/automatum_users?business_id=${_automatumBusinessId}&email`,
    headers: {
      'Authorization': `${accessToken.jwtToken}`
    }
  };

  try {
    const response = await axios(config)
    return response.data
  } catch (error) {
    console.log('error', error)
  }
}


/**
 * Get one user information
 * @param request {object}
*/
export const getUserInfo = async (email) => {
  const accessToken = await getAccessToken()
  const config = {
    method: 'get',
    url: `${_automatumURL}/automatum_users?business_id=${_automatumBusinessId}&email=${email}`,
    headers: {
      'Authorization': `${accessToken.jwtToken}`
    }
  };

  try {
    const response = await axios(config)
    return response.data
  } catch (error) {
    throw new Error(error)
  }
}

/**
 * Update user
 * @param request {object}
 * @returns void
*/
export const createUser = async (request) => {
  const data = JSON.stringify(request);

  const config = {
    method: 'post',
    url: `${_automatumURL}/automatum_auth`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data
  };

  try {
    await axios(config)
  } catch (error) {
    throw new Error(error)
  }

}


/**
 * Update user
 * @param request {object}
 * @returns void
*/
export const updateUser = async (request) => {
  const accessToken = await getAccessToken()
  const data = JSON.stringify(request);

  const config = {
    method: 'post',
    url: `${_automatumURL}/automatum_users`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${accessToken.jwtToken}`
    },
    data: data
  };

  try {
    await axios(config)
  } catch (error) {
    throw new Error(error)
  }

}

/**
 * Get all products
 * @param request {object}
*/
export const getAllProducts = async (business_id) => {

  const accessToken = await getAccessToken()
  var config = {
    method: 'get',
    url: `${_automatumURL}/automatum_products?business_id=${business_id}&email`,
    headers: {
      'Authorization': `${accessToken.jwtToken}`,
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios(config)
    return response.data
  } catch (error) {
    throw new Error(error)
  }
}


/**
 * Get one user information
 * @param request {object}
*/
export const getProductInfo = async (product_id) => {
  const accessToken = await getAccessToken()
  const config = {
    method: 'get',
    url: `${_automatumURL}/automatum_products?business_id=${_automatumBusinessId}&product_id=${product_id}`,
    headers: {
      'Authorization': `${accessToken.jwtToken}`
    }
  };

  try {
    const response = await axios(config)
    return response.data
  } catch (error) {
    throw new Error(error)
  }
}

/**
 * Create user
 * @param request {object}
 * @returns void
*/
export const createProduct = async (request) => {
  const accessToken = await getAccessToken()
  var data = JSON.stringify(request);

  var config = {
    method: 'post',
    url: `${_automatumURL}/automatum_products`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${accessToken.jwtToken}`
    },
    data: data
  };

  try {
    await axios(config)
  } catch (error) {
    throw new Error(error)
  }

}

/**
 * Create user
 * @param request {object}
 * @returns void
*/
export const createMarketPlaceRecord = async (request) => {
  console.log(request)
  const accessToken = await getAccessToken()
  var data = JSON.stringify(request);

  var config = {
    method: 'post',
    url: `${_automatumURL}/automatum_marketplace`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${accessToken.jwtToken}`
    },
    data: data
  };

  try {
    await axios(config)
  } catch (error) {
    throw new Error(error)
  }

}

/**
 * Create user
 * @param request {object}
 * @returns void
*/
export const getMarketPlaceRecord = async (id, email) => {
  const accessToken = await getAccessToken()

  var config = {
    method: 'get',
    url: `${_automatumURL}/automatum_marketplace?id=${id}&email=${email}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${accessToken.jwtToken}`
    },
  };

  try {
    const response = await axios(config)
    return response.data
  } catch (error) {
    throw new Error(error)
  }

}


export const publishMessage = async (request) => {
  const accessToken = await getAccessToken()
  var data = JSON.stringify(request);

  var config = {
    method: 'post',
    url: `${_automatumURL}/publish_message`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${accessToken.jwtToken}`
    },
    data: data
  };

  try {
    await axios(config)
  } catch (error) {
    throw new Error(error)
  }

}

/**
 * send enquiry
 * @param request {object}
 * @returns void
*/
export const sendEnquiry = async (request) => {
  var data = JSON.stringify(request);

  var config = {
    method: 'post',
    url: `${_automatumURL}/submitform`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data
  };

  try {
    await axios(config)
  } catch (error) {
    throw new Error(error)
  }

}

/**
 * generate signed url
 * @param request {object}
 * @returns void
*/
export const generateSignedUrl = async (request) => {
  const accessToken = await getAccessToken()
  var data = JSON.stringify(request);

  var config = {
    method: 'get',
    url: `${_automatumURL}/signedurl?uri=${request}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${accessToken.jwtToken}`
    },
    data: data
  };

  try {
    const response = await axios(config)
    return response.data
  } catch (error) {
    throw new Error(error)
  }

}


/**
 * Generate random uuid
 * @returns uuid
*/
export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}