var CryptoJS = require("crypto-js");
import { validateValue } from "./validateValue";

const automatumSecret = process.env.VUE_APP_AUTOMATUM__SECRET

// Encrypt Item
export const encryptItem = (plainText) => {
  try {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(plainText), automatumSecret).toString();;
    return encrypted
  } catch (error) {
    console.error("Something went wrong while endecoding your payload")
  }
}

// Decrypt Item
export const decryptItem = (encrypted) => {
  try {
    if(!validateValue(encrypted)) return 
    const decrypted = CryptoJS.AES.decrypt(encrypted, automatumSecret).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted)
  } catch (error) {
    console.error("Something went wrong while decoding your payload")
  }
}



