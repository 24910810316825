
export default {
  namespaced: true,
  state: {
    customers: [],
  },
  mutations: {
    RESET_CUSTOMERS_MODULE(state) {
      state.customers = []
    },
    UPDATE_CUSTOMERS(state, value) {
      state.customers = value
    }
  },
  actions: {
    //
  },
}
