import { getUserData } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { updateUser } from "../../../aws/Api";


export default {
  namespaced: true,
  state: {
    userInfo: null
  },
  getters: {},
  mutations: {
    RESET_USER_MODULE(state) {
      state.userInfo = null
    },
    UPDATE_USER_INFO(state, value) {
      state.userInfo = value
    }
  },
  actions: {
    // query user information
    ADD_USER_INFO({ commit }) {
      const userInfo = getUserData()
      if (userInfo) {
        commit('UPDATE_USER_INFO', userInfo)
      } else {
        return
      }
    },
    // edit user information
    async EDIT_USER_INFO({ commit }, value) {
      if (value) {
        try {
          await updateUser(value)
          localStorage.setItem("userData", JSON.stringify(value))
          commit('UPDATE_USER_INFO', value)
          this._vm.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hey ${value.fullName}!`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully updated your records with Automatum!`,
            },
          });
        } catch (error) {
          this._vm.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hey ${value.fullName}!`,
              icon: "CoffeeIcon",
              variant: "error",
              text: `An error occured while we were updating your account!`,
            },
          });
        }
      }
    }
  },
}
