
export default {
  namespaced: true,
  state: {
    marketIntergration: [],
    userInfo: null
  },
  getters: {},
  mutations: {
    RESET_MARKET_MODULE(state) {
      state.marketIntergration = []
      state.userInfo = null
    },
    UPDATE_MARKET_INTERGRATION(state, value) {
      state.marketIntergration = value
    }
  },
  actions: {
    SAVE_MARKET_INTERGRATION({ commit }, value) {
      commit("UPDATE_MARKET_INTERGRATION", value)
    }
  },
}
