import { getAllProducts } from "../../../aws/Api";

export default {
  namespaced: true,
  state: {
    products: [],
    cartItems: [],
    selectedProduct: {},
    cartItemsCount: 0,
  },
  getters: {},
  mutations: {
    RESET_ECOMMERCE_MODULE(state) {
      state.products = []
      state.cartItems = []
      state.selectedProduct = {}
      state.cartItemsCount = 0
    },
    // update cart count
    UPDATE_CART_ITEMS_COUNT(state, count) {
      state.cartItemsCount = count
    },
    // update selected product
    UPDATE_SELECTED_PRODUCT(state, value) {
      state.selectedProduct = value
    },
    // update cart items
    UPDATE_CART_ITEMS(state, value) {
      state.cartItems = value
    },
    // update business products
    UPDATE_BUSINESS_PRODUCTS(state, value) {
      state.products = value
    }
  },
  actions: {
    // fetch products
    async FETCH_BUSINESS_PRODUCTS({ commit }) {
      // fetch all products
      const business_products = await getAllProducts('5a25a404-1d50-4134-aa66-f9d0e5622ab3');
      commit("UPDATE_BUSINESS_PRODUCTS", business_products)
    },
    // update cart
    UPDATE_CART({ commit }, value) {
      commit("UPDATE_CART_ITEMS", value)
    },
    // select item
    SELECT_ITEM({ commit }, value) {
      commit("UPDATE_SELECTED_PRODUCT", value)
    }
  },
}
