import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import productStoreModule from '@/views/apps/product/productStoreModule'
import customerStoreModule from '@/views/apps/customers/customerStoreModule'
import userStoreModule from '@/views/apps/user/userStoreModule'
import marketStoreModule from '@/views/apps/market-intergration/marketStoreModule'
import homeStoreModule from '@/views/home/homeStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    'app-products': productStoreModule,
    'app-customers': customerStoreModule,
    'app-users': userStoreModule,
    'app-market': marketStoreModule,
    'app-home': homeStoreModule
  },
  strict: process.env.DEV,
})
